/* eslint-disable vue/html-self-closing */
<template>

	<div>
		<!-- Filters -->
		<bank-accounts-list-filters
			v-if="bankOptions"
			:username-filter.sync="usernameFilter"
			:user-id-filter.sync="userIdFilter"
			:fullname-filter.sync="fullnameFilter"
			:email-filter.sync="emailFilter"
			:phone-filter.sync="phoneFilter"
			:bankaccount-filter.sync="bankaccountFilter"
			:province-id-filter.sync="provinceIdFilter"
			:status-filter.sync="statusFilter"
			:bank-id-filter.sync="bankIdFilter"
			:searching.sync="isSearching"
			:status-options="statusOptions"
			:bank-options="bankOptions"
		/>
		<b-card
			no-body
			class="mb-0"
		>
			<b-table
				ref="refBankAccountListTable"
				class="position-relative table-white-space max-height-table"
				sticky-header
				head-variant="light"
				:no-border-collapse="true"
				:items="fetchBankAccounts"
				responsive
				:fields="tableColumns"
				primary-key="id"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

				<!-- Column: User -->
				<template #cell(username)="data">
					<b-link
						v-if="$can('view', 'userdetail')"
						:to="{ name: 'apps-users-view', params: { id: data.item.user_id } }"
					>
						{{ data.item.user.username }}
					</b-link>
					<span v-else>{{ data.item.user.username }}</span>
				</template>

				<template #cell(fullname)="data">
					{{ data.item.user.name }}
				</template>

				<template #cell(bank)="data">
					{{ data.item.bank_name }}
				</template>

        <template #cell(bank_account)="data">
          <b-overlay
              :show="loadingShowAccount"
              variant="transparent"
              no-wrap
          />
          {{ data.item.bank_account }}
          <span
              class="cursor-pointer"
              v-if="$can('view', 'bankaccount')"
              @click.prevent="toggleShow(data.item)"
          >
            <feather-icon :icon="toggleIcon(data.item)" />
          </span>
        </template>

				<template #cell(province)="data">
					{{ data.item.province_name }}
				</template>

				<template #cell(status)="data">
					<b-badge
						pill
						:variant="`light-${resolveUserBankAccountStatus(data.item.stat).variant}`"
						class="text-capitalize"
					>
						<feather-icon :icon="resolveUserBankAccountStatus(data.item.stat).icon" />
						{{ resolveUserBankAccountStatus(data.item.stat).label }}
					</b-badge>
				</template>

				<template #cell(createdAt)="data">
					{{ data.item.created_at ? formatDateTime(data.item.created_at).replace('.000000Z', '') : 'n/a' }}
				</template>

			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>

						<b-pagination
							v-model="currentPage"
							:total-rows="totalBankAccounts"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>
	</div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/filter'
import BankAccountsListFilters from './BankAccountsListFilters.vue'
import useBankAccountsList from './useBankAccountsList'
import bankAccountStoreModule from './bankAccountStoreModule'
import bankStoreModule from '@/views/local-setting/local/bankStoreModule'
import router from "@/router";

export default {
	components: {
		BankAccountsListFilters,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
    BOverlay,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
  data() {
    return {
      loadingShowAccount: false,
    };
  },
	setup() {
		const PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME = 'payment-bank-account'
		const LOCAL_SETTING_BANK_STORE_MODULE_NAME = 'local-setting-bank'

		// Register module
		if (!store.hasModule(PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME)) store.registerModule(PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME, bankAccountStoreModule)
		if (!store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME)) store.registerModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME, bankStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME)) store.unregisterModule(PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME)
			if (store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME)) store.unregisterModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME)
		})

		const {
			fetchBankAccounts,
			bankOptions,
			tableColumns,
			perPage,
			currentPage,
			totalBankAccounts,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refBankAccountListTable,
			refetchData,

			// UI
			resolveUserBankAccountStatus,
			statusOptions,

			// Extra Filters
			usernameFilter,
			userIdFilter,
			emailFilter,
			fullnameFilter,
			phoneFilter,
			bankaccountFilter,
			provinceIdFilter,
			bankIdFilter,
			statusFilter,
      isSearching
		} = useBankAccountsList()

		return {
			fetchBankAccounts,
			bankOptions,
			tableColumns,
			perPage,
			currentPage,
			totalBankAccounts,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refBankAccountListTable,
			refetchData,

			// UI
			resolveUserBankAccountStatus,

			statusOptions,

			// Extra Filters
			usernameFilter,
			userIdFilter,
			emailFilter,
			fullnameFilter,
			phoneFilter,
			bankaccountFilter,
			provinceIdFilter,
			bankIdFilter,
			statusFilter,
      isSearching,

			formatDateTime,
		}
	},

  methods: {
    async getBankAccount(bank) {
      this.loadingShowAccount = true;
      if (!bank.bank_account_show) {
        bank.bank_account_hide = bank.bank_account;
        await this.$store
            .dispatch("payment-bank-account/viewBankAccount", bank.id)
            .then((response) => {
              bank.bank_account_show = response.data.data;
            })
            .then(() => {
              setTimeout(() => {
                this.loadingShowAccount = false;
              }, 500);
            });
      } else {
        setTimeout(() => {
          this.loadingShowAccount = false;
        }, 500);
      }
    },
    async toggleShow(bank) {
      await this.getBankAccount(bank);
      if (bank.bank_account === bank.bank_account_show) {
        bank.bank_account = bank.bank_account_hide;
      } else if (bank.bank_account === bank.bank_account_hide || !bank.bank_account_hide) {
        bank.bank_account = bank.bank_account_show;
      }
    },
    toggleIcon(bank) {
      return (!bank.bank_account_hide || bank.bank_account === bank.bank_account_hide) ? "EyeOffIcon" : "EyeIcon";
    },
  }
}
</script>